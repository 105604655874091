import * as React from "react";
import Baseline from "../images/baseline.inline.svg";
import InstagramLogo from "../images/instagram-logo.inline.svg";

const Maintenance = () => {
  return (
    <main className="flex h-screen w-full flex-col items-center justify-center bg-pink font-gotham-ultra text-xl">
      <Baseline className="w-96 pb-24 text-white" />
      <h1 className="pb-12 text-2xl">Coming Soon ...</h1>
      <div className="lg:pb-2">
        <a
          href="https://www.instagram.com/popmodelsagency"
          target="_blank"
          rel="noreferrer"
          className="inline text-white"
        >
          Rejoignez-nous sur Instagram
          <InstagramLogo
            alt="instagram-logo"
            className="mb-3 inline w-7 pl-2 lg:w-10"
          />
        </a>
      </div>
    </main>
  );
};

export default Maintenance;
